"use client";

import { styled } from "styled-components";
import { Heading1Text, IconButton } from "@gnist/design-system";
import { tokens } from "@gnist/themes/tokens.css.js";

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${tokens.spacing.xs};
  margin-inline: auto;
  max-width: 672px;
  text-align: center;
`;

export const HeadSection = () => {
  return (
    <HeadWrapper>
      <Heading1Text>Finn din neste bil</Heading1Text>
      <p>
        Søk blant tusen bruktbiler fra forhandlere over hele Norge, eller få
        hjelp til å velge riktig bil etter dine behov med vår nye veileder.
      </p>
      <IconButton
        href="https://finn.bruktbil.no/"
        label="Få hjelp til å finne riktig bruktbil"
        icon="external"
        showLabel="left"
        $as="a"
      />
    </HeadWrapper>
  );
};
